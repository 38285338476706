<!--
 * @Author: luoyan
 * @Date: 2021-10-29 15:21:18
 * @LastEditTime: 2021-11-16 14:18:17
 * @Description: file content  侧边栏
-->
<template>
  <div class="Suspended">
    <div
         class="Suspendedimg"
         @mouseenter="visable = true"
         @mouseleave="visable = false"
    >
      <div class="ej" id="ej"></div>
    </div>
    <div class="Suspendedimg" @click="openGwc()">
      <div class="ej2 car" id="ej2"></div>
    </div>
    <div class="Suspendedimg" @click="clickCustomer()">
      <div class="ej3" id="ej3"></div>
    </div>
    <div  @click="toBuyClick">
      <div class="SuspendedText">我要求购</div>
    </div>
    <div class="SuspendedText" style="margin-top: 38px; padding-bottom: 24px">
      <div class="ej4" id="ej4" @click="gotoTop"></div>
    </div>
    <div class="shop-sidebar" v-if="visable">
      <div class="shop-sidebar-banner">
        <img src="../assets/img/2946.svg" alt="大大买钢"/>
      </div>
      <div class="shop-sidebar-list">
        <div class="sidebar-left">
          <img src="https://ddmallimg.ddmg.com/public/assets/img/index/shops/wx01.png" alt="大大买钢"/>
          <p>官方微信</p>
        </div>
        <div class="sidebar-right">
          <img src="https://ddmallimg.ddmg.com/public/assets/img/index/shops/ddmg_wx.jpg" alt="大大买钢"/>
          <p>大大买钢小程序</p>
        </div>
      </div>
    </div>

    <div :class="{ gwcBox: true, onGwc: gwc == true }">
      <div class="gwcBox-header">
        <div class="gwcBox-header-title">购物车</div>
        <div @click="gwc = false" class="gwcBox-header-close">
          <i class="el-icon-close"></i>
        </div>
      </div>
      <div class="gwcBox-body" v-if="goodsCartList && goodsCartList.length > 0">
        <div class="gwcBox-body-list">
          <div
               class="gwcBox-body-item"
               v-for="(item, index) in goodsCartList"
               :key="index"
          >
            <div class="gwcBox-body-item-company">
              <div class="shoppingcart_datatop">
                <el-checkbox
                     @change="onChiceAll(item)"
                     v-model="item.check"
                ></el-checkbox>
                <p class="hiddenOne">{{ item.sellerCompanyName }}</p>
              </div>
              <div
                   class="shoppingcart_datadiv"
                   v-for="(comp, idx) in item.list"
                   :key="idx"
              >
                <div class="shoppingcart_datadiv-title">
                  <el-checkbox
                       @change="onChice(comp)"
                       v-model="comp.check"
                  ></el-checkbox>
                  <p class="hiddenOne">
                    {{ comp.productName }} {{ comp.originPlace }}
                    {{ comp.material }} {{ comp.specifications }}
                  </p>
                </div>
                <p style="padding-left: 20px" class="hiddenOne">

                  <span v-if="comp.warehouse">{{ comp.warehouse.name }}</span>
                  <span style="color: #ff4747; margin-left: 15px">
                    <span class="cat-format-price" v-if="comp.price != '0.00'"
                    >{{ comp.price }}/吨</span
                    >
                    <span class="cat-format-price" v-if="comp.price == '0.00'"
                    >面议</span
                    >
                  </span>
                </p>
                <p
                     style="padding-left: 20px"
                     class="shoppingcart_datadiv-jishu"
                >
                  件数
                  <span class="jishu-width"
                  ><el-input-number
                       v-model="comp.quantity"
                       :min="1"
                       @change="quantityChange(comp)"
                       size="mini"
                       :max="comp.avail_quantity * 1"
                  ></el-input-number
                  ></span>
                </p>
                <p
                     style="padding-left: 20px"
                     class="shoppingcart_datadiv-jishu"
                >
                  重量
                  <span class="jishu-width"
                  ><el-input
                       @change="weightChange(comp)"
                       v-model="comp.weight"
                       size="mini"
                       :min="0"
                       :max="comp.avail_weight * 1"
                  ></el-input
                  ></span>
                </p>
                <div class="shoppingcart_datadiv-delete" @click="deleteClick(comp)">删除</div>
              </div>
            </div>
          </div>
        </div>
        <div class="gwcBox-body-footer">
          <div class="gwcBox-body-footer-count">
            <div class="gwcBox-body-footer-dsc" v-if="gwcChecked == true">
              <div>
                共
                <p style="color: #ff4747">{{ gwcCount.count }}</p>
                件,
                <p>{{ gwcCount.weight }}吨</p>
                <p style="color: #ff4747; font-size: 21px">
                  ￥{{ gwcCount.price }}
                </p>
              </div>
            </div>
            <div class="gwcBox-body-footer-dsc nochecked" v-else>
              <div>
                共<span style="color: #ff4747">{{ gwcCountInit.count }}</span
              >件, {{ gwcCountInit.weight }}吨
                <span style="color: #ff4747; font-size: 21px"
                >￥{{ gwcCountInit.price }}</span
                >
              </div>
            </div>

            <p>
              <button
                   @click="getAddOrder"
                   type="button"
                   class="btn cart-button"
                   style="
                  font-size: 14px;
                  background-color: #ff4747;
                  color: #ffffff;
                  outline: none;
                  border-radius: 0;
                "
              >
                去下单
              </button>
            </p>
            <p>请勾选同一仓库同一卖家的商品</p>
          </div>
          <!-- <div class="gwcBox-body-footer-invalid">
            <p>失效资源</p>
            <i class="el-icon-arrow-up"></i>
          </div> -->
        </div>
      </div>
      <div class="gwcBox-body gwcBox-null" v-else>
        <div>
          <i class="el-icon-shopping-cart-1"></i>
        </div>
        <div>购物车没有商品哦!</div>
      </div>
    </div>

  </div>
</template>

<script>
    import util from "@/libs/util.js";
    import * as CryptoJS from "crypto-js";
    import storage from "@/utils/storage";
    import { mapMutations, mapActions, mapState } from "vuex";
    export default {
        name: "footerNav",
        props: {},
        data() {
            return {
                visable: false,
                gwc: false,
                gwcChecked: false,
                num: 0,
                input: "",
                gwcCountInit: {
                    price: "-",
                    weight: "-",
                    count: "-",
                },
                gwcCount: {
                    price: 0,
                    weight: 0,
                    count: 0,
                },
                userInfo:{},
            };
        },
        watch: {
            gwcOrderList() {
                this.$message.success("已添加到购物车");
            },
        },
        created() {
            if( storage.getStorage("userInfo")){
                this.userInfo =  storage.getStorage("userInfo");
            }
        },
        methods: {
            ...mapActions("ddmg/gwc", ["getGoodsCart", "getEditGWC","deleteGwc"]),
            ...mapMutations("ddmg/gwc", ["setGwcOrderData"]),
            toBuyClick(){
                this.$router.push({ name: 'toBuy' })
            },
            deleteClick(item){
                this.deleteGwc(item).then((res) => {
                    if (res.msgcode === 1) {
                        this.$message({
                            message: "操作成功",
                            type: "success",
                        });
                    }
                });
            },

            //打开购物车
            openGwc() {
                let isToken = this.isToken()
                if(isToken){
                    this.getGoodsCart(this.userInfo.company_id).then((res) => {
                        this.gwc = true;
                    });
                }
            },
            //去下单
            getAddOrder() {
                let idArr = [];
                let checkList = [];
                this.goodsCartList.forEach((item) => {
                    item.list.forEach((sitem) => {
                        if (sitem.check == true) {
                            idArr.push(sitem.id);
                            checkList.push(sitem);
                        }
                    });
                });
                if(checkList.length > 1){
                    if(checkList.find(obj=> obj.sellerCompanyId != checkList[0].sellerCompanyId)){
                        this.$message("请选择同一卖家进行下单！");
                        return
                    }else if(checkList.find(obj=> obj.warehouseId != checkList[0].warehouseId)){
                        this.$message("请选择同一仓库进行下单！");
                        return
                    }else{
                        this.$router.push({
                            name: "cart",
                            query: { id: idArr.toString(), type: 2 },
                        }); //type 1现货资源 2购物车
                    }
                }else{
                    this.$router.push({
                        name: "cart",
                        query: { id: idArr.toString(), type: 2 },
                    }); //type 1现货资源 2购物车
                }
            },
            gotoTop() {
                window.scroll({
                    top: 0,
                    behavior: "smooth",
                });
            },
            //全选
            onChiceAll(item) {
                item.list.forEach((res) => {
                    res.check = item.check;
                });
                this.getTotal();
            },
            //单选商品
            onChice(val) {
                console.log(val);
                this.goodsCartList.forEach((xitem) => {
                    let itemArr = [];
                    xitem.list.forEach((item) => {
                        if (item.check == true) {
                            itemArr.push(item.check);
                        }
                    });
                    if (itemArr.length == xitem.list.length) {
                        xitem.check = true;
                    } else {
                        xitem.check = false;
                    }
                });
                this.getTotal();
            },
            //合计
            getTotal() {
                let a = false;
                this.gwcCountInit.price = 0;
                this.gwcCountInit.weight = 0;
                this.gwcCountInit.count = 0;
                this.goodsCartList.forEach((xitem) => {
                    xitem.list.forEach((item) => {
                        if (item.check == true) {
                            this.gwcCountInit.count += item.quantity * 1;
                            this.gwcCountInit.weight += Number((item.weight * 1).toFixed(4));
                            this.gwcCountInit.price += Number((item.newPrice * 1).toFixed(2));
                            if (item.price == "" || item.price == "0.00") {
                                //其中如果有面议时，总价为面议
                                a = true;
                            }
                        }
                    });
                });
                if (a) {
                    this.gwcCountInit.price = "面议";
                }
            },
            //修改件数
            quantityChange(item) {
                // if (item.adjustment_factor != 0 && item.adjustment_factor != "") {
                //   item.weight = (
                //     item.quantity *
                //     1 *
                //     (item.adjustment_factor * 1)
                //   ).toFixed(4);
                //   if (item.price != "" && item.price != "0.00") {
                //     item.newPrice = (item.price * 1 * item.weight).toFixed(2);
                //   } else {
                //     item.newPrice = 0;
                //   }
                // }
                if (item.price != "" && item.price != "0.00") {
                    item.newPrice = (item.price * 1 * item.weight).toFixed(2);
                } else {
                    item.newPrice = 0;
                }
                let body = {
                    id: item.id, //类型：Number  必有字段  备注：购物车 id
                    quantity: item.quantity, //类型：Number  必有字段  备注：数量
                    weight: item.weight,
                };
                if (!body.weight || !body.weight) {
                    body.weight = 1;
                }

                this.getEditGWC(body);
                this.getTotal();
            },
            //修改重量
            weightChange(item) {
                if (item.price != "" && item.price != "0.00") {
                    item.newPrice = (item.price * 1 * (item.weight * 1)).toFixed(2);
                } else {
                    item.newPrice = 0;
                }
                let body = {
                    id: item.id, //类型：Number  必有字段  备注：购物车 id
                    quantity: item.quantity, //类型：Number  必有字段  备注：数量
                    weight: item.weight,
                };
                if (body.quantity == 0 || !body.quantity) {
                    body.quantity = 1;
                }
                this.getEditGWC(body);
                this.getTotal();
            },
            clickCustomer(){
               this.toCustomer()
            },
            clickCustomer1(){
            let nonce = Math.floor(Math.random() * (99999999 - 10000000) + 10000000);
            let timestamp = new Date().getTime();
            let web_token = this.userInfo.company_id;
            let name = this.userInfo.user_name || '游客';
            let c_tags = '大汉电子商务有限公司';
            let c_phone =  this.userInfo.user_mobile || '';
            let im_user_key = '4304fada159f7405df25af33add47f38';
            let sign_str = 'nonce=' + nonce + '&timestamp=' + timestamp + '&web_token=' + web_token + '&' + im_user_key;
            let signature = CryptoJS.SHA1(sign_str).toString().toUpperCase();
            let url = 'https://1730813.s4.udesk.cn/im_client/?web_plugin_id=19417&channel=%E5%B0%8F%E7%A8%8B%E5%BA%8F&signature='+signature+'&nonce='+nonce+'&timestamp='+timestamp+'&web_token='+web_token+'&c_cf_客户ID='+web_token+'&c_name='+name+'&c_tags='+c_tags+'&c_phone='+c_phone+'&c_cf_是否web端=1'
            window.open(url, "_target");
            // let agent_id = ''
            // if(option.agent_id){
            //     agent_id = option.agent_id;
            //     session_key = option.agent_id+option.id;
            // }
            // let companyArr = Config.customer_company;
            // if(option.type == 'shop'){
            //     // 店铺样式
            //     let product_title = option.product_title;
            //     let product_image_shop = option.product_image || 'https://ddmallimg.ddmg.com/images/mr/shop.png';
            //     let main = option.main;
            //     let dec = option.dec;
            //     c_tags = companyArr.includes(product_title)?product_title:'大汉电子商务有限公司';
            //     url = 'https://1730813.s4.udesk.cn/im_client/?web_plugin_id=19417&channel=%E5%B0%8F%E7%A8%8B%E5%BA%8F&signature='+signature+'&nonce='+nonce+'&timestamp='+timestamp+'&web_token='+web_token+'&c_cf_客户ID='+web_token+'&product_title='+product_title+'&product_image='+product_image_shop+'&product_%E6%88%90%E4%BA%A4%E9%87%8F%0A='+dec+'&product_%E4%B8%BB%E8%90%A5%0A='+main+'&c_name='+name+'&c_tags='+c_tags+
            //         '&session_key='+session_key+'&c_cf_当前咨询公司='+product_title+'&agent_id='+agent_id+'&c_phone='+c_phone+'&c_cf_是否web端=1'
            // }else if(option.type=='index'){
            //     c_tags = '大汉电子商务有限公司';
            //     url = 'https://1730813.s4.udesk.cn/im_client/?web_plugin_id=19417&channel=%E5%B0%8F%E7%A8%8B%E5%BA%8F&signature='+signature+'&nonce='+nonce+'&timestamp='+timestamp+'&web_token='+web_token+'&c_cf_客户ID='+web_token+'&c_name='+name+'&c_tags='+c_tags+'&c_phone='+c_phone+'&c_cf_是否web端=1'
            // }else{
            //     // 商品样式
            //     let product_title = option.product_title;
            //     let product_image = option.product_image || 'https://ddmallimg.ddmg.com/images/mr/mr_poster.png';
            //     let price =  option.price;
            //     let dec = option.dec;
            //     c_tags = companyArr.includes(dec)?dec:'大汉电子商务有限公司';
            //     url = 'https://1730813.s4.udesk.cn/im_client/?web_plugin_id=19417&channel=%E5%B0%8F%E7%A8%8B%E5%BA%8F&signature='+signature+'&nonce='+nonce+'&timestamp='+timestamp+'&web_token='+web_token+'&c_cf_客户ID='+web_token+'&product_title='+encodeURIComponent(product_title)+'&product_%E5%85%AC%E5%8F%B8='+dec+'&product_%E4%BB%B7%E6%A0%BC=￥'+price+'&product_%E4%BB%B7%E6%A0%BC_style=color:rgb(253,123,100);margin-top:5px;&product_send=true&product_image='+product_image+'&c_name='+name+'&c_tags='+c_tags+
            //         '&session_key='+session_key+'&c_cf_当前咨询公司='+dec+'&c_phone='+c_phone+'&c_cf_是否web端=1'
            // }
            // window.open(url)


            // let data= util.cookies.get("foreground_user");
            // if (data) {
            //    console.log('登录');
            // } else {
            //    console.log('没登录');
            // }
          },
        },
        computed: {
            ...mapState("ddmg/gwc", {
                goodsCartList: (state) => state.goodsCartList,
            }),
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" >
  .Suspended {
    width: 58px;
    background-color: #ffffff;
    margin: 0 auto;
    position: fixed;
    right: 0;
    top: 180px;
    z-index: 9998;
    box-shadow: 0px 4px 13px 0px rgba(93, 93, 93, 0.15);
  }
  .Suspended .Suspendedimg {
    width: 58px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #e8e8e8;
  }
  .SuspendedText {
    width: 32px;
    height: 46px;
    color: #333333;
    font-size: 16px;
    margin: 0 auto;
    margin-top: 24px;
    cursor: pointer;
  }
  .SuspendedText:hover {
    color: #ef2147;
  }
  /*.Suspended .Suspendedimg div{*/
  /*    width: 25px;*/
  /*    height: 25px;*/
  /*    margin: 0px 10px;*/
  /*    display: inline-block;*/
  /*    text-align: center;*/
  /*    cursor:pointer;*/
  /*    margin-top: 8px;*/
  /*}*/

  .Suspended .Suspendedimg .ej {
    width: 33px;
    height: 33px;
    background: url(../assets/img/3848.svg) no-repeat center;
    cursor: pointer;
  }
  .Suspended .Suspendedimg .ej2 {
    background: url(../assets/img/3205.svg) no-repeat center;
    width: 32px;
    height: 29px;
    cursor: pointer;
  }
  .Suspended .Suspendedimg .ej3 {
    background: url(../assets/img/3940.svg) no-repeat center;
    width: 32px;
    height: 32px;
    cursor: pointer;
  }
  .Suspended .SuspendedText .ej4 {
    background: url(../assets/img/3208.svg) no-repeat center;
    width: 32px;
    height: 25px;
  }
  /*.Suspended .Suspendedimg .bs{*/
  /*    background: url(https://ddmallimg.ddmg.com/public/assets/img/index/layout/floatbs.png) no-repeat center;*/
  /*}*/
  /*.Suspended .Suspendedimg .top{*/
  /*    background: url(https://ddmallimg.ddmg.com/public/assets/img/index/layout/floattop.png) no-repeat center;*/
  /*}*/

  /*.Suspended .Suspendedimg .car:hover{*/
  /*    background: url(https://ddmallimg.ddmg.com/public/assets/img/index/layout/floatbulecar.png) no-repeat center;*/
  /*}*/
  /*.Suspended .Suspendedimg .top:hover{*/
  /*    background: url(https://ddmallimg.ddmg.com/public/assets/img/index/layout/floatbuletop.png) no-repeat center;*/
  /*}*/
  /*.Suspended .Suspendedimg .bs:hover{*/
  /*    background: url(https://ddmallimg.ddmg.com/public/assets/img/index/layout/floatbulebs.png) no-repeat center;*/
  /*}*/
  /*.Suspended .Suspendedimg .ej:hover{*/
  /*    background: url(https://ddmallimg.ddmg.com/public/assets/img/index/layout/floatbuleej.png) no-repeat center;*/
  /*}*/

  .shop-sidebar {
    width: 198px;
    position: absolute;
    left: -208px;
    top: -30px;
    flex-direction: column;
    background-color: #fff;
    border-radius: 4px;
    z-index: 10000;

    transition: all 0.2s;
  }

  .shop-sidebar .shop-sidebar-banner img {
    width: 198px;
  }

  .shop-sidebar .shop-sidebar-list {
    width: 198px;
    display: flex;
    text-align: center;
    justify-content: space-between;
    margin: 0 auto;
  }

  .shop-sidebar .shop-sidebar-list .sidebar-left,
  .shop-sidebar .shop-sidebar-list .sidebar-right {
    border: 1px solid #00000029;
    width: 95px;
    height: 95px;
    margin-top: 8px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
    position: relative;
  }
  /* .shop-sidebar .shop-sidebar-list .sidebar-left:hover,
           .shop-sidebar .shop-sidebar-list .sidebar-right:hover{
              transform:scale(2,2);
              transition: all 0.4s;
              z-index: 99;
              background-color: #fff;
          }*/
  .shop-sidebar .shop-sidebar-list img {
    width: 69px;
  }

  .shop-sidebar .shop-sidebar-list p {
    font-size: 12px;
    font-weight: 400;
    color: #333333ff;
    cursor: pointer;
    caret-color: rgba(0, 0, 0, 0);
  }

  .gwcBox {
    width: 258px;
    height: 85%;
    position: fixed;
    top: 10%;
    right: -260px;
    z-index: 99;
    box-shadow: 0px 4px 13px 0px rgb(93 93 93 / 15%);
    background-color: #ffffff;
    transition: all 1s ease;
    overflow: hidden;
  }
  .onGwc {
    right: 0 !important;
    transition: all 1s ease;
  }
  .gwcBox-header {
    display: flex;
    padding: 20px;
    font-size: 18px;
    height: 20px;
  }
  .gwcBox-header-title {
    text-align: center;
    width: 200px;
  }
  .gwcBox-header-close {
    width: 18px;
  }
  .gwcBox-body {
    background-color: #ebebeb;
    height: 100%;
  }
  .gwcBox-body-list {
    overflow-y: auto;
    height: calc(100% - 200px);
  }

  .gwcBox-null {
    text-align: center;
    display: flex;
    flex-direction: column;
    padding-top: 20%;
    font-size: 18px;
    color: #999999;
  }
  .gwcBox-null div:nth-child(1) {
    font-size: 10rem;
    opacity: 0.5;
  }
  .gwcBox-body-footer {
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 12px;
    color: #999999;
    margin-top: 20px;
  }
  .gwcBox-body-footer p {
    margin: 7px 0;
  }
  .gwcBox-body-footer .cart-button {
    width: 211px;
    height: 44px;
    border: none;
  }
  .shoppingcart_datadiv {
    border-bottom: 1px solid #dddddd;
    background-color: #ffffff;
    padding: 20px;
    position: relative;
  }
  .shoppingcart_datadiv-delete {
    position: absolute;
    /* top: -20px; */
    right: 20px;
    bottom: 50px;
    cursor: pointer;
    color: rgb(102, 102, 102);
    opacity: 0;
    font-size: 14px;
    transition: all 0.5s ease;
  }
  .shoppingcart_datadiv-delete:hover {
    transition: all 0.5s ease;
    opacity: 1;
  }
  .shoppingcart_datatop {
    line-height: 41px;
    height: 41px;
    padding-left: 20px;
    background-color: #f8f8f8;
    display: flex;

    border-bottom: 1px solid #ebebeb;
    color: #333333;
  }
  .shoppingcart_datadiv-title {
    width: 218px;
    display: flex;
  }

  .hiddenOne {
    margin-left: 5px;
  }
  .shoppingcart_datadiv .hiddenOne {
    font-size: 13px;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
    white-space: nowrap;
    color: inherit;
  }
  .shoppingcart_datadiv:hover {
    background-color: #fafcff;
  }

  .shoppingcart_datadiv-jishu {
    display: flex;
    color: #999999;
    font-size: 14px;
    line-height: 40px;
  }
  .shoppingcart_datadiv-jishu .jishu-width {
    margin-left: 10px;
    width: 100px;
  }
  .shoppingcart_datadiv-jishu .jishu-width .el-input-number--mini {
    width: 100px !important;
  }
  .gwcBox-body-footer-invalid {
    height: 50px;
    line-height: 50px;
    margin-top: 15px;
    background-color: #f4f4f4;
    user-select: none;
    font-size: 13px;
    color: #222;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .gwcBox-body-footer-invalid p {
    margin-right: 10px;
  }
  .gwcBox-body-footer-dsc {
    margin: 0 auto;
    text-align: center;
  }
  .gwcBox-body-footer-dsc div {
    margin: 0 20px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .gwcBox-body-footer-dsc div p {
    margin: 0 0 0 4px;
    font-size: 14px;
  }

  .gwcBox-body-footer-dsc.nochecked {
    margin-left: 60px;
  }
</style>
